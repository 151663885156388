import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import RestaurantIcon from "@mui/icons-material/Restaurant";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import ListUsers from "./modules/admin_users/list_users";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../assets/functions_helper";
import { ButtonAnimated } from "../components/resources";
import MainRestaurants from "./modules/restaurants/main_restaurants";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import SnackBar from "../components/snackBar";
import TextLogo from "../assets/images/text_logo.png";
import LogoLogo from "../assets/images/logo_logo.png";
import NotificationIcon from "../assets/images/notification_icon.png";
import { useWindowWidth } from "../hooks/useWindowWidth";
import CallsV2 from "./modules/calls/callV2";

const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth - 10}px`, // Ajusta el margen izquierdo considerando el margen de 10px del Drawer
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 20,
    }),
    marginTop: "120px", // Ajusta la posición del main para que baje por debajo del header
    height: "calc(100vh - 120px)", // Ajusta la altura del main restando el tamaño del header
    overflowY: "auto", // Permite el desplazamiento vertical si el contenido excede la altura del main
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px - 30px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: "#070724",
  borderRadius: "20px",
  border: "1px black solid",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  height: "90px",
  margin: "10px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "50px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
}));

const DrawerCustomized = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "#070724",
    color: "white",
    borderRadius: 20,
    margin: "10px", // Añadido margen de 10px
    boxSizing: "border-box",
    height: "calc(100% - 20px)", // Ajusta el alto del Drawer
  },
}));

const ProfileButton = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: 240,
  height: 70,
  background: "rgba(255, 255, 255, 0.08)",
  boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.15) inset",
  borderRadius: 100,
  border: "0.5px solid rgba(255, 255, 255, 0.17)",
  backdropFilter: "blur(10px)",
}));

export default function DashBoard({ setIsLoggedIn }) {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [activeView, setActiveView] = React.useState("Usuarios");

  const [pagesActiveView, setPagesActiveView] = React.useState([
    "Usuarios",
    "Restaurantes",
    "Admin. Restaurante",
  ]);

  const [openDialog, setOpenDialog] = React.useState(false);

  const [newPassword, setNewPassword] = React.useState("");

  const [oldPassword, setOldPassword] = React.useState("");

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  const [loadingChangePassword, setLoadingChangePassword] =
    React.useState(false);

  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");

  const navigate = useNavigate();

  const width = useWindowWidth();

  React.useEffect(() => {
    switch (localStorage.getItem("rol")) {
      case "1": // ID para el rol de Admin
        setActiveView("Usuarios");
        setPagesActiveView(["Usuarios", "Restaurantes"]);
        break;
      case "2": // ID para el rol de Usuario
        setActiveView("Admin. Restaurante");
        setPagesActiveView(["Admin. Restaurante", "Calls"]);
        break;
      // Agrega más casos según sea necesario
      default:
        setPagesActiveView(["Usuarios", "Restaurantes", "Admin. Restaurante"]);
        setActiveView("Usuarios");
    }
  }, []); // Dependencia del efecto

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    localStorage.removeItem("token");
    localStorage.removeItem("tokenType");
    setIsLoggedIn(false);
    navigate("/");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderView = () => {
    switch (activeView) {
      case "Usuarios":
        return <ListUsers />;
      case "Restaurantes":
        return <MainRestaurants />;
      case "Admin. Restaurante":
        return <MainRestaurants />;
      case "Calls":
        return <CallsV2 />;
      default:
        return <ListUsers />;
    }
  };

  const handlePasswordChange = () => {
    if (oldPassword === "" || newPassword === "") {
      setSnackBarMessage("Debe llenar todos los campos");
      setSnackBarOpen(true);
      return;
    } else {
      if (oldPassword === newPassword) {
        setSnackBarMessage(
          "Las contraseñas no pueden ser iguales, por favor intente de nuevo"
        );
        setSnackBarOpen(true);
        setLoadingChangePassword(false);
        return;
      } else if (newPassword !== confirmNewPassword) {
        setSnackBarMessage(
          "La nueva contraseña no coincide con la confirmación, por favor intente de nuevo"
        );
        setSnackBarOpen(true);
        setLoadingChangePassword(false);
        return;
      } else {
        makeRequest(
          "PUT",
          `users/password`,
          {
            old_password: oldPassword,
            new_password: newPassword,
          },
          "application/json",
          localStorage.getItem("token"),
          localStorage.getItem("tokenType"),
          () => {
            navigate("/login");
          }
        )
          .then((response) => {
            console.log(response);
            setSnackBarMessage("Contraseña cambiada correctamente");
            setSnackBarOpen(true);
            setOpenDialog(false);
            setLoadingChangePassword(false);
          })
          .catch((error) => {
            console.log("Error al cambiar la contraseña");
            setSnackBarMessage("Error al cambiar la contraseña");
            setSnackBarOpen(true);
            setLoadingChangePassword(false);
          });
      }
    }
  };

  const list = () => (
    <>
      <DrawerHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={LogoLogo}
            alt="Logo"
            style={{ width: "75.79", height: "72.39" }}
          />
          <img
            src={TextLogo}
            alt="Logo"
            style={{ width: "75.79", height: "72.39" }}
          />
        </Box>
      </DrawerHeader>
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {pagesActiveView.map((text, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{
              borderRadius: "12px",
              marginTop: index === 0 ? "50px" : "36px",
              maxWidth: "240px",
              background:
                activeView === text
                  ? "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)"
                  : "#070724",
              transition: "background 0.3s ease",
              height: "50px",
            }}
          >
            <ListItemButton onClick={() => setActiveView(text)}>
              <ListItemIcon
                sx={{
                  color: "white",
                }}
              >
                {text === "Usuarios" ? <PersonIcon /> : null}
                {text === "Restaurantes" ? <RestaurantIcon /> : null}
                {text === "Admin. Restaurante" ? <RestaurantIcon /> : null}
                {text === "Calls" ? <SwapCallsIcon /> : null}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={width > 900}
        sx={{
          "@media (max-width:900px)": {
            margin: "0px",
            marginTop: "10px",
          },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
          }}
        >
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ mr: 2, display: width < 900 ? "block" : "none" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {width > 900 && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                width: "calc(100% - 300px)",
              }}
            >
              {activeView}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "300px",
            }}
          >
            <IconButton color="inherit" aria-label="notifications">
              <img
                src={NotificationIcon}
                alt="Notification"
                style={{ width: "30px", height: "30px" }}
              />
            </IconButton>

            <ProfileButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleMenu}
              sx={{
                "@media (max-width:900px)": {
                  width: 200,
                  height: 60,
                },
                "@media (max-width:600px)": {
                  width: 180,
                  height: 60,
                },
              }}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  color: "white",
                  fontSize: 18,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Profile Name
              </Typography>
              <Typography
                variant="h9"
                noWrap
                component="div"
                sx={{
                  color: "#796DB8",
                  fontSize: 10,
                  fontFamily: "Arial",
                  fontWeight: "400",
                  wordWrap: "break-word",
                }}
              >
                Mi Cuenta
              </Typography>
            </ProfileButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "rgba(255, 255, 255, 1)", // Set a less transparent or solid background color
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Optional shadow for better visibility
                
              },
            }}
          >
            <MenuItem onClick={handleLogOut} sx={{
              color: "black"
            }}>Cerrar Sesion</MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setOpenDialog(true);
              }}
              sx={{
                color: "black"
              }}
            >
              Cambiar Contraseña
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <DrawerCustomized
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={width < 900 ? open : true}
      >
        {list()}
      </DrawerCustomized>
      {width < 900 && (
        <SwipeableDrawer
          anchor={"left"}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              background: "#070724",
              color: "white",
              borderRadius: 5,
              margin: "10px", // Añadido margen de 10px
              boxSizing: "border-box",
              height: "calc(100% - 20px)", // Ajusta el alto del Drawer
              width: drawerWidth,
            },
          }}
        >
          {list()}
        </SwipeableDrawer>
      )}
      <Main
        open={width > 900}
        sx={{
          marginTop: "110px",
          background: "#070724",
          borderRadius: "20px",
          marginRight: "10px",
          marginBottom: "10px",
        }}
      >
        {renderView()}
      </Main>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Cambiar contraseña</DialogTitle>
        <DialogContent>
          {loadingChangePassword ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                id="oldPassword"
                label="Antigua Contraseña"
                type="password"
                fullWidth
                onChange={(e) => setOldPassword(e.target.value)}
                value={oldPassword}
              />
              <TextField
                autoFocus
                margin="dense"
                id="newPassword"
                label="Nueva Contraseña"
                type="password"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
              />
              <TextField
                autoFocus
                margin="dense"
                id="confirmPassword"
                label="Confirmar Contraseña"
                type="password"
                fullWidth
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonAnimated
            onClick={() => {
              setLoadingChangePassword(true);
              handlePasswordChange();
            }}
          >
            Enviar
          </ButtonAnimated>
        </DialogActions>
      </Dialog>
      {snackBarOpen ? (
        <SnackBar
          openSnackBar={snackBarOpen}
          setOpenSnackBar={setSnackBarOpen}
          messageSnackBar={snackBarMessage}
        />
      ) : null}
    </Box>
  );
}

import { useState, useEffect, useRef } from "react";
import { DialogHeader } from "../../../components/tailwind_componentes/Dialog";
import chairImage from "../../../assets/images/chair.png";
import { makeRequest } from "../../../assets/functions_helper";
import { Box, CircularProgress, MenuItem } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { CustomSelect } from "../../../components/textFields";
import {
  CustomDialog,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import { CloseButton, SendButton } from "../../../components/buttons";

function generateOrder(order) {
  const orderDetailsObject = JSON.parse(order.order_details);
  const productList = orderDetailsObject.products.map((product) => ({
    name: product.name,
    quantity: product.quantity,
  }));

  return productList;
}

export default function CallsV2() {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTableCompletedInfo, setSelectedTableCompletedInfo] =
    useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState("");
  const wsRef = useRef(null); // Referencia para el WebSocket

  const [openModalNotification, setOpenModalNotification] = useState(false);
  // const navigate = useNavigate();

  useEffect(() => {
    makeRequest(
      "GET",
      "restaurant_admin/list_my_restaurants?page=1&size=999&order_by=id&order=desc",
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        // navigate("/login");
      }
    ).then((response) => {
      if (response && response.restaurants) {
        setRestaurants(response.restaurants);
        setLoading(false);
      }
    });
  }, []);

  const handleTableClick = (index, table) => {
    setSelectedTable(index);
    setSelectedTableCompletedInfo(table);
    setOpenModalNotification(true);
  };

  const resolveNotification = (tableIndex, notificationId) => {
    setTables((prevTables) =>
      prevTables.map((table, index) =>
        index === tableIndex
          ? {
              ...table,
              notifications: table.notifications.filter(
                (n) => n.id !== notificationId
              ),
            }
          : table
      )
    );
    setSelectedNotification(null);
  };

  useEffect(() => {
    if (!selectedRestaurantId) return;

    // Cerrar la conexión WebSocket existente si existe
    if (wsRef.current) {
      wsRef.current.close();
    }

    // Crear una nueva conexión WebSocket
    const newWs = new WebSocket(
      `wss://eatery-z81ocn81.b4a.run/ws/table_notification/${selectedRestaurantId}`
    );

    newWs.onopen = () => {
      console.log("WebSocket connected");
    };

    newWs.onmessage = (event) => {
      console.log("Mensaje recibido del WebSocket:", event.data);
      const newCall = JSON.parse(event.data);
      newCall.isNew = true;

      const timezone = moment.tz.guess();
      newCall.created_at_local = moment
        .utc(newCall.created_at)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm:ss");

      const randomType = newCall.call_type;
      const newNotification = {
        id: newCall.call_public_id
          ? newCall.call_public_id
          : Math.random().toString(36).substr(2, 9),
        type: randomType,
        details: `New ${randomType
          .toLowerCase()
          .replace(/_/g, " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())}`,
        timestamp: new Date(),
        message: randomType === "commented_call" ? newCall.comment : undefined,
        order: randomType === "order_call" ? generateOrder(newCall) : undefined,
      };

      setTables((prevTables) =>
        prevTables.map((table) =>
          table.public_id === newCall.restaurant_table_public_id
            ? {
                ...table,
                notifications: [...table.notifications, newNotification],
              }
            : table
        )
      );
    };

    newWs.onclose = () => {
      console.log("WebSocket disconnected");
    };

    wsRef.current = newWs; // Guardar el WebSocket en la referencia

    fetchData(selectedRestaurantId);

    // Limpiar el WebSocket al desmontar o al cambiar de restaurante
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, [selectedRestaurantId]); // `wsRef` no entra como dependencia.

  const fetchData = async (restaurantId) => {
    const startTime = new Date();
    const endTime = new Date(startTime.getTime() - 60 * 60 * 1000);

    const startTimeStr = startTime.toISOString();
    const endTimeStr = endTime.toISOString();

    console.log("Start Time (UTC):", startTimeStr);
    console.log("End Time (UTC):", endTimeStr);

    const response = await makeRequest(
      "GET",
      `restaurant_admin/calls/${restaurantId}?start_time=${encodeURIComponent(
        endTimeStr
      )}&end_time=${encodeURIComponent(startTimeStr)}`,
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {
        console.log("Redirect to login");
      }
    );

    const timezone = moment.tz.guess();
    const updatedCalls = response.map((call) => ({
      ...call,
      created_at_local: moment
        .utc(call.created_at)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm:ss"),
    }));
    console.log("Calls:", updatedCalls);

    makeRequest(
      "POST",
      "restaurants/user/tables",
      2,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      null,
      null,
      () => {}
    )
      .then((response) => {
        console.log(updatedCalls);
        if (response && "total_tables" in response) {
          const arrTables = response.tables.map((table) => {
            table.notifications = [];
            return table;
          });

          const attendedCalls = updatedCalls.filter(
            (call) => call.attended === false
          );

          let initialNewsNotifications = [];

          initialNewsNotifications = attendedCalls.map((call) => {
            return {
              id: call.call_public_id
                ? call.call_public_id
                : Math.random().toString(36).substr(2, 9),
              tableId: call.restaurant_table_public_id
                ? call.restaurant_table_public_id
                : call.table_public_id
                ? call.table_public_id
                : null,
              type: call.call_type,
              details: `New ${call.call_type
                .toLowerCase()
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}`,
              timestamp: new Date(),
              message:
                call.call_type === "commented_call" ? call.comment : undefined,
              order:
                call.call_type === "order_call"
                  ? //generateOrder(call)
                    []
                  : undefined,
            };
          });
          console.log(initialNewsNotifications);
          const updatedTables = arrTables.map((table) => {
            const tableNotifications = initialNewsNotifications.filter(
              (notification) => notification.tableId === table.public_id
            );

            return {
              ...table,
              notifications: [...table.notifications, ...tableNotifications],
            };
          });

          setTables(updatedTables);
        } else {
          setTables(Array.from({ length: 0 }, () => ({ notifications: [] })));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTables((prevTables) =>
        prevTables.map((table) => {
          const hasOldNotification = table.notifications.some((notification) => {
            const notificationTime = new Date(notification.timestamp).getTime();
            const currentTime = new Date().getTime();
            return currentTime - notificationTime > 1000; // 30s
          });

          return { ...table, hasOldNotification };
        })
      );
    }, 1000); // Verificamos cada 1s

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="min-h-screen text-white m-10">
          <CustomSelect
            value={selectedRestaurantId}
            onChange={(e) => setSelectedRestaurantId(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ width: "300px" }}
          >
            <MenuItem value="">
              <em>Seleccione un restaurante</em>
            </MenuItem>
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.name}
              </MenuItem>
            ))}
          </CustomSelect>
          <main className="p-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-9">
              {tables.map((table, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-center cursor-pointer group"
                    onClick={() => handleTableClick(index, table)}
                  >
                    {/* Título de la Mesa */}
                    <div className="mb-7 text-sm font-semibold">
                      {table.name}
                    </div>
                    {/* Contenedor de Mesa y Sillas */}
                    <div 
                      className={`relative group-hover:scale-105 transition-transform duration-300 ease-in-out ${
                        table.hasOldNotification ? "animate-pulse" : ""
                      }`}
                    >
                      {/* Mesa */}
                      <svg
                        className="w-16 h-16 text-gray-700 group-hover:text-gray-500 transition-colors"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="10"
                          y="10"
                          width="80"
                          height="80"
                          fill={table.hasOldNotification ? "red" : "currentColor"}
                          rx="15"
                          ry="15"
                        />
                        <rect
                          x="15"
                          y="15"
                          width="70"
                          height="70"
                          fill="#D4A35A"
                          stroke={table.hasOldNotification ? "red" : "#000000"}
                          strokeWidth="5"
                          rx="10"
                          ry="10"
                        />
                      </svg>
                      {/* Sillas */}
                      {Array.from({ length: 4 }).map((_, chairIndex) => {
                        const totalChairs = 4; // Número total de sillas
                        const angle = (Math.PI * 2 * chairIndex) / totalChairs; // Ángulo para cada silla
                        const radius = 45; // Distancia desde el centro de la mesa

                        // Calculamos las posiciones en el círculo
                        const offsetX = Math.cos(angle) * radius;
                        const offsetY = Math.sin(angle) * radius;

                        // Ajuste adicional en X y Y para centrar las sillas
                        const adjustX = -13; // Ajuste en el eje X (positivo o negativo)
                        const adjustY = -13; // Ajuste en el eje Y (positivo o negativo)

                        // Calculamos la rotación para que apunten hacia el centro
                        const rotation = (angle * 180) / Math.PI + 90; // Convertimos de radianes a grados y ajustamos orientación

                        return (
                          <img
                            key={chairIndex}
                            src={chairImage} // Imagen de la silla
                            alt="Chair"
                            className="absolute w-7 h-7 object-contain transition-transform transform group-hover:scale-110"
                            style={{
                              top: `calc(50% + ${offsetY + adjustY}px)`, // Se suma el ajuste en Y
                              left: `calc(50% + ${offsetX + adjustX}px)`, // Se suma el ajuste en X
                              transform: `rotate(${rotation}deg)`, // Apunta hacia el centro de la mesa
                            }}
                          />
                        );
                      })}

                      {/* Notificación */}
                      {table.notifications.length > 0 && (
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full w-6 h-6 flex items-center justify-center text-white text-xs font-bold animate-pulse">
                          {table.notifications.length}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </main>

          <CustomDialog
            open={openModalNotification}
            onClose={() => setOpenModalNotification(false)}
          >
            <CustomDialogContent
              className="text-white"
              aria-describedby="dialog-description"
            >
              <DialogHeader>
                <CustomDialogTitle>
                  Notificaciones para mesa{" "}
                  {selectedTableCompletedInfo
                    ? selectedTableCompletedInfo.name
                    : ""}
                </CustomDialogTitle>
              </DialogHeader>
              {selectedTable !== null &&
                !selectedNotification &&
                (tables[selectedTable].notifications.length > 0 ? (
                  <ul className="space-y-2">
                    {tables[selectedTable].notifications.map((notification) => (
                      <li
                        key={notification.id}
                        className="p-2 rounded transition-all duration-300 ease-in-out"
                        id={`notification-${notification.id}`}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <p className="font-bold">
                              {notification.type === "commented_call"
                                ? "Llamada con Mensaje"
                                : notification.type === "order_call"
                                ? "Llamada con Menu"
                                : "Llamada"}
                            </p>
                            <p>Nueva Llamada</p>
                            <p className="text-sm text-gray-400">
                              {notification.timestamp.toLocaleString()}
                            </p>
                          </div>
                          <div className="space-x-2">
                            <SendButton
                              variant="secondary"
                              size="sm"
                              sx={{ fontSize: "15px", padding: "10px 8px" }}
                              onClick={() => {
                                setSelectedNotification(notification);
                              }}
                            >
                              Detalle
                            </SendButton>
                            <CloseButton
                              variant="destructive"
                              size="sm"
                              sx={{ fontSize: "15px", padding: "10px 8px" }}
                              onClick={() => {
                                console.log(notification);
                                const element = document.getElementById(
                                  `notification-${notification.id}`
                                );
                                if (element) {
                                  element.classList.add(
                                    "animate-bounce",
                                    "opacity-0"
                                  );
                                  setTimeout(() => {
                                    resolveNotification(
                                      selectedTable,
                                      notification.id
                                    );
                                  }, 500);
                                }
                                makeRequest(
                                  "PUT",
                                  `restaurant_admin/mark_call/${notification.id}`,
                                  {},
                                  "application/json",
                                  localStorage.getItem("token"),
                                  localStorage.getItem("tokenType"),
                                  null,
                                  null,
                                  () => {
                                    // navigate("/login");
                                  }
                                ).then((response) => {
                                  console.log(response);
                                });
                              }}
                            >
                              Resolver
                            </CloseButton>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-center py-4">No Hay Notificaciones</p>
                ))}
              {selectedNotification && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">
                    {selectedNotification.type === "commented_call"
                      ? "Llamada con Mensaje"
                      : selectedNotification.type === "order_call"
                      ? "Llamada con Menu"
                      : "Llamada"}
                  </h3>
                  <p>Hora: {selectedNotification.timestamp.toLocaleString()}</p>
                  {selectedNotification.type === "commented_call" && (
                    <div>
                      <h4 className="font-semibold">Mensaje:</h4>
                      <p>{selectedNotification.message}</p>
                    </div>
                  )}
                  {selectedNotification.type === "order_call" && (
                    <div>
                      <h4 className="font-semibold">Orden:</h4>
                      <ul className="list-disc list-inside">
                        {selectedNotification.order?.map((item, index) => (
                          <li key={index}>
                            {item.name} - {item.quantity ? item.quantity : "1"}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <SendButton onClick={() => setSelectedNotification(null)}>
                    Volver a Notificaciones
                  </SendButton>
                </div>
              )}
            </CustomDialogContent>
          </CustomDialog>
        </div>
      )}
    </>
  );
}

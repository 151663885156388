import React, { useEffect, useState } from "react";
import { Box, CircularProgress, DialogContentText, TextField } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { CloseButton, SendButton } from "../../../components/buttons";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import { makeRequest } from "../../../assets/functions_helper";
import TextLogo from "../../../assets/images/text-logo-calls.png";
import LogoLogo from "../../../assets/images/logo-calls.png";

// Componente de alerta para Snackbar
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotifyButton = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [loading, setLoading] = useState(false);
  const [commentTable, setCommentTable] = useState("");
  const [openCommentModal, setOpenCommentModal] = useState(false);

  let { tableNumber } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
  }, []);

  const handleNotifyWaiter = () => {
    setOpenCommentModal(true);
  };

  const confirmNotifyWaiter = async () => {
    let url = `notify_waiters/${tableNumber}`;
    let obj = {
      table_public_id: tableNumber ? tableNumber : "3test",
      code: localStorage.getItem("tableCode"),
      comment: commentTable,
      latitude: 12,
      longitude: 12,
      call_type: commentTable === "" ? "normal_call" : "commented_call",
      order: { products: [], total_price: 0 },
    };

    try {
      makeRequest(
        "POST",
        url,
        obj,
        "application/json",
        localStorage.getItem("token"),
        localStorage.getItem("tokenType"),
        null,
        null,
        () => {}
      )
        .then(() => {
          setSnackbarMessage("✅ ¡El mesero ha sido notificado exitosamente!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setCommentTable("");
          setOpenCommentModal(false);
        })
        .catch((error) => {
          if (error.error_code === "0005") {
            // Mapeamos el mensaje en español con los segundos restantes

            const segundosRestantes = error.value;
            setSnackbarMessage(
              `⏳ Debes esperar ${segundosRestantes} segundo${
                segundosRestantes > 1 ? "s" : ""
              } antes de volver a llamar al mesero.`
            );
            setSnackbarSeverity("warning");
          } else {
            setSnackbarMessage("❌ Error al notificar al mesero.");
            setSnackbarSeverity("error");
          }
          setOpenSnackbar(true);
          setOpenCommentModal(false);
        });
    } catch (error) {
      setSnackbarMessage("❌ Error al notificar al mesero.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            backgroundColor: "#070724",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background: "rgba(255, 255, 255, 0.08)",
              boxShadow: "0px 0px 6px rgba(255, 255, 255, 0.15) inset",
              borderRadius: 15,
              border: "0.50px rgba(255, 255, 255, 0.70) solid",
              backdropFilter: "blur(10px)",
              padding: "20px",
              width: "500px",
              height: "600px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            {/* Logos */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={LogoLogo}
                alt="Logo"
                style={{ width: "75px", height: "72px" }}
              />
              <img src={TextLogo} alt="Texto Logo" />
            </Box>

            {/* Botones */}
            <SendButton
              variant="contained"
              color="primary"
              onClick={() => navigate(`/restaurantMenu/${tableNumber}`)}
              sx={{ width: "80%" }}
            >
              Ver el Menú
            </SendButton>
            <SendButton
              variant="contained"
              color="secondary"
              onClick={handleNotifyWaiter}
              sx={{ width: "80%" }}
            >
              Llamar al Mesero
            </SendButton>
          </Box>
        </Box>
      ) : (
        <CircularProgress />
      )}

      {/* Modal de comentario */}
      <CustomDialog
        open={openCommentModal}
        onClose={() => {
          setCommentTable("")
          setOpenCommentModal(false)
        }}
      >
        <CustomDialogTitle>¿Deseas dejar un comentario?</CustomDialogTitle>
        <CustomDialogContent>
          <DialogContentText>
            El comentario es opcional, pero ayudará al mesero a atender mejor tu
            solicitud.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Comentario (Opcional)"
            type="text"
            onChange={(e) => setCommentTable(e.target.value)}
            value={commentTable}
            multiline
            minRows={4}
            sx={{
              width: "100%",
            }}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenCommentModal(false)}>
            Cancelar
          </CloseButton>
          <SendButton onClick={confirmNotifyWaiter}>Enviar Llamado</SendButton>
        </CustomDialogActions>
      </CustomDialog>

      {/* Snackbar para mostrar notificaciones */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotifyButton;

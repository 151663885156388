import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Estilo personalizado para los tabs
const CustomTab = styled(Tab)(({ theme }) => ({
  width: 158,
  height: 52,
  background: '#00001C',
  borderRadius: 7,
  textAlign: 'center',
  color: 'white',
  fontSize: 15,
  fontWeight: 400,
  wordWrap: 'break-word',
  opacity: 0.5,
  '&.Mui-selected': {
    background: 'linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)',
    opacity: 1,
    color: 'white',
    border: '1px rgba(255, 255, 255, 1) solid'
  },
}));

// Estilo personalizado para Tabs
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    minHeight: 'unset',
    padding: '0',
  },
  display: 'flex',
  flexWrap: 'nowrap', // Evita que los elementos pasen a la siguiente línea
  minWidth: 'max-content', // Asegura que los elementos mantengan su tamaño
  overflowX: 'auto', // Permitir desplazamiento horizontal
  maxWidth: '100%', // Limitar el ancho al contenedor
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

// Componente DynamicTabs
const DynamicTabs = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    console.log("Cambiando a tab:", newValue);
    setValue(newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column', // Asegurar que el contenido esté debajo de los tabs
      width: '100%',
      maxWidth: '100%',
    }}>
      {/* Contenedor de tabs con scroll horizontal */}
      <Box sx={{
        overflowX: 'auto',
        scrollbarWidth: 'thin',
        '-ms-overflow-style': 'none',
        '&::-webkit-scrollbar': { display: 'none' },
      }}>
        <CustomTabs value={value} onChange={handleChange} aria-label="dynamic tabs">
          {tabs.map((tab, index) => (
            <CustomTab key={index} label={tab.label} value={index} />
          ))}
        </CustomTabs>
      </Box>

      {/* Contenido de cada tab */}
      <Box sx={{ padding: 2, marginTop: 2 }}>
        {tabs[value]?.content}
      </Box>
    </Box>
  );
};

export default DynamicTabs;
